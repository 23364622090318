import { Alert, Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { localLogError } from 'src/views/utils/logger';
import { getWSConfig } from 'src/views/utils/Utils';
import * as Yup from 'yup';

interface ChangeEmailDialogProps {
  open: boolean;
  onClose: () => void;
  email: string;
  setEmail: (email: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ChangeEmailDialog: FC<ChangeEmailDialogProps> = ({ open, onClose, email, setEmail }) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const intl = useIntl();
  const config = getWSConfig();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          success: "false",
          submit: null
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(8, intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.insert_current_password" }))
            .max(255).required(intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.insert_current_password" })),
          email: Yup.string().min(8, intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.insert_new_email" }))
            .max(255).required(intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.insert_new_email" })).matches(emailRegex,
              intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.insert_new_email" }))
            .test('email-unique', intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.same_email" }), value => value !== email),
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            let params = {};
            params['email'] = values.email;
            params['old_password'] = values.password;

            axios.patch(process.env.REACT_APP_SERVER_URL + '/auth/1.0/patient', params, config)
              .then(function (response) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.saved" }), { variant: 'success', persist: false, color: 'green' })
                setEmail(values.email);
              })
              .catch(function (error) {
                localLogError("update patient error: ", error.response.data);
                if (error.response.status === 401) {
                  setErrors({ submit: intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.error_incorrect_password" }) });
                }
                else {
                  setErrors({ submit: intl.formatMessage({ id: "accountManagment.Configuration.ChangeEmail.error_others" }) });
                }
              })

          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>

            <Typography variant='h4' gutterBottom>
              <FormattedMessage id="accountManagment.Configuration.ChangeEmail.change_email" />
            </Typography>
            <Box mt={2}> </Box>
            <Card>
              <CardContent>
                <Typography variant='h5' gutterBottom>
                  <FormattedMessage id="accountManagment.Configuration.ChangeEmail.current_email" />
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {email}
                </Typography>
                <Box mt={3}> </Box>

                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={<FormattedMessage id="accountManagment.Configuration.ChangeEmail.new_email" />}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={<FormattedMessage id="accountManagment.Configuration.ChangeEmail.current_password" />}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      autoComplete="current-password"
                    />
                  </Grid>
                </Grid>
                <Box
                  alignItems="center"
                  display="flex"
                  mt={2}
                  ml={1}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="accountManagment.Configuration.ChangeEmail.compulsory" />
                  </Typography>
                </Box>
                {errors.submit && (
                  <Box mt={3}>
                    <Alert severity="error">{errors.submit}</Alert>
                  </Box>
                )}
                {errors.success === "true" && (
                  <Box mt={3}>
                    <Alert severity="success">
                      <FormattedMessage id="accountManagment.Configuration.ChangeEmail.email_modified" />
                    </Alert>
                  </Box>
                )}
              </CardContent>

              <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="accountManagment.Configuration.ChangeEmail.change_email" />
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default ChangeEmailDialog;
