import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Avatar from 'react-avatar';
import { Box, Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Patient } from 'src/types/patient';

interface ProfileDetailsProps {
  className?: string;
  user: Patient;
}

const useStyles = makeStyles((theme) => ({

  root: {},
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const ProfileDetails: FC<ProfileDetailsProps> = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const patient: Patient = JSON.parse(localStorage.getItem('user'));

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Avatar
            name={patient.name + " " + patient.last_name}
            maxInitials={2}
            size="10rem"
            round={true}
            color="#E0E0E0"
            className={classes.avatar}
          />
          <Typography
            className={classes.name}
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {patient.name} {" "} {patient.last_name}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            Digimevo
          </Typography>
        </Box>
      </CardContent>

    </Card>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
