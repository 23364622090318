import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Patient } from 'src/types/patient';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { getWSConfig } from 'src/views/utils/Utils';

interface DeleteAccountDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    verticalAlign: 'middle',
    marginRight: 8
  },
}));

const DeleteAccountDialog: FC<DeleteAccountDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const patient: Patient = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();
  const config = getWSConfig();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    axios.delete(process.env.REACT_APP_SERVER_URL + '/patient/1.0/', config)
      .then(function (response) {
        enqueueSnackbar(<FormattedMessage id="accountManagment.Configuration.DeleteAccount.account_deleted" />, {
          variant: 'success'
        });
        history.push('/login/' + patient.organization_id);
      })
      .catch(function (error) {
        if (process.env.REACT_APP_BUILD_ENV === 'local' && error && error.response && error.response.data) {
          console.error(error.response.data)
        }
      })
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Typography variant="h4" gutterBottom>
        <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account" />
      </Typography>
      <Box mt={2}> </Box>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account_consequences" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1">
              <HighlightOffIcon className={classes.icon} color="error" />
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account_consequence_1" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1">
              <HighlightOffIcon className={classes.icon} color="error" />
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account_consequence_2" />
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1">
              <HighlightOffIcon className={classes.icon} color="error" />
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account_consequence_3" />
            </Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
              style={{ marginRight: '16px' }}
            >
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.cancel_delete_account" />
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              id="deleteAccountButton"
            >
              <FormattedMessage id="accountManagment.Configuration.DeleteAccount.delete_account" />
            </Button>
          </Box>
        </CardContent>
      </Card>
    </CustomDialog>
  );
};

export default DeleteAccountDialog;
