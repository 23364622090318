import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { Patient } from 'src/types/patient';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import useSettings from 'src/hooks/useSettings';
import { deleteKalturaVideoPlayerIdFromLocalStorage, getAndStoreKalturaVideoPlayerId } from 'src/views/utils/Utils';

interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: any | null
}

interface AuthContextValue extends AuthState {
    method: 'JWT',
    loginByOrganizationPatientId: (organization_patient_id: string, password: string, organization_id: number) => Promise<void>;
    iframeLogin(userToken: string);
    loginById: (id: number, password: string) => Promise<void>;
    logout: () => void;
    registerSimpleById: (
        patient_id: number,
        birth_date: string,
        lastThreeDigits: string,
        password: string
    ) => Promise<void>;
    register: (
        name: string,
        last_name: string,
        avatar: string,
        email: string,
        gender_id: number,
        city: string,
        birth_date: string,
        mobile: string,
        medical_number: string,
        organization_id: number,
        password: string) => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitialiseAction = {
    type: 'INITIALISE';
    payload: {
        isAuthenticated: boolean;
        user: any
    };
};

type LoginByIdAction = {
    type: 'LOGINBYID';
    payload: {
        user: any
    };
};
type LoginByEmailAction = {
    type: 'LOGINBYEMAIL';
    payload: {
        user: any
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type RegisterAction = {
    type: 'REGISTER';
    payload: {
        user: any
    };
};

type RegisterSimpleByIdAction = {
    type: 'REGISTERSIMPLEBYID';
    payload: {
        user: any
    };
};

type IframeLoginAction = {
    type: 'IFRAMELOGIN';
    payload: {
        user: any
    };
}

type Action =
    | InitialiseAction
    | LoginByEmailAction
    | IframeLoginAction
    | LoginByIdAction
    | LogoutAction
    | RegisterSimpleByIdAction
    | RegisterAction;

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
};

const isValidToken = (accessToken: string): boolean => {
    if (!accessToken) {
        return false;
    }

    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        delete axios.defaults.headers.common.Authorization;

    }
};

const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isInitialised: true,
                isAuthenticated,
                user
            };
        }
        case 'IFRAMELOGIN': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGINBYEMAIL': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGINBYID': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'REGISTERSIMPLEBYID': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'JWT',
    loginByOrganizationPatientId: () => Promise.resolve(),
    iframeLogin: () => Promise.resolve(),
    loginById: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    registerSimpleById: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const getNotificationList = async () => {
        let param_token = window.localStorage.getItem('accessToken');
        await axios.post(process.env.REACT_APP_SERVER_URL + '/patient_notification/1.0/list/patient/notifications',
            { patient_id: JSON.parse(localStorage.getItem('user')).patient_id }, { headers: { Authorization: `Bearer ${param_token}` } })
            .then(function (response) {
                window.localStorage.setItem('notifications', JSON.stringify(response.data));
                window.dispatchEvent(new Event('notificationsUpdate'));
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    }

    const loginByOrganizationPatientId = async (organization_patient_id: string, password: string, organization_id: number) => {
        let params = {
            organization_patient_id: organization_patient_id,
            password: password,
            organization_id: organization_id
        }
        const response = await axios.post<{ accessToken: string }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signin/patient', params);
        const { accessToken } = response.data;

        localStorage.setItem('showTopBar', 'true');
        getAndStoreKalturaVideoPlayerId()

        setSession(accessToken);
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'LOGINBYEMAIL',
            payload: {
                user
            }
        });
    };

    const iframeLogin = async (accessToken: string) => {
        setSession(accessToken);
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'IFRAMELOGIN',
            payload: {
                user
            }
        });
    }

    const loginById = async (id: number, password: string) => {

        const response = await axios.post<{ accessToken: string }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signin/patient/by/id', { id, password });
        const { accessToken } = response.data;

        localStorage.setItem('showTopBar', 'true');
        getAndStoreKalturaVideoPlayerId()

        setSession(accessToken);
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'LOGINBYID',
            payload: {
                user
            }
        });
    };

    const prepareUser = async (
        accessToken: string
    ) => {
        let config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const user = (await axios.get(process.env.REACT_APP_SERVER_URL + '/auth/1.0/me', config)).data;
        if (user.avatar === undefined || user.avatar === null || user.avatar === "") {
            user.avatar = "/static/logo_avatar.jpg";
        }
        if (user.city === undefined || user.city === null) {
            user.city = "";
        }
        if (user.mobile === undefined || user.mobile === null) {
            user.mobile = "";
        }
        if (user.language === undefined || user.language === null) {
            user.language = "es"
        }
        if (user.birth_date !== undefined && user.birth_date !== "") {
            const birthdate = new Date(user.birth_date);
            if (birthdate) {
                user.birth_date = birthdate.getFullYear() + "-" + (birthdate.getMonth() + 1) + "-" + birthdate.getDate()
            }
        }
        localStorage.setItem('user', JSON.stringify(user));
        window.dispatchEvent(new Event('userUpdated'));
        getNotificationList();
        return user;
    }

    const prepareGuestUser = async () => {
        const user: Patient = {
            patient_id: 0,
            avatar: "/static/logo_avatar.jpg",
            name: "Invitado",
            last_name: "",
            email: "",
            gender_id: null,
            birth_date: "",
            organization_id: 0,
            language: "es"
        }

        localStorage.setItem('user', JSON.stringify(user));

        return user;
    }

    const logout = () => {
        setSession(null);
        localStorage.removeItem('user')
        window.dispatchEvent(new Event('userUpdated'));
        dispatch({ type: 'LOGOUT' });
        localStorage.setItem('showTopBar', 'true');
        deleteKalturaVideoPlayerIdFromLocalStorage()
    };

    const register = async (
        params
    ) => {
        const response = await axios.post<{ accessToken: string; user: Patient }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signup/patient', params);
        const { accessToken } = response.data;

        localStorage.setItem('showTopBar', 'true');
        getAndStoreKalturaVideoPlayerId()

        window.localStorage.setItem('accessToken', accessToken);
        setSession(accessToken);
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    const registerSimpleById = async (
        params
    ) => {
        const response = await axios.post<{ accessToken: string; user: Patient }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signup/patient/simple/by/id', params);
        const { accessToken } = response.data;

        localStorage.setItem('showTopBar', 'true');
        getAndStoreKalturaVideoPlayerId()

        window.localStorage.setItem('accessToken', accessToken);
        setSession(accessToken);
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'REGISTERSIMPLEBYID',
            payload: {
                user
            }
        });
    };


    useEffect(() => {
        const initialise = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    const user = await prepareUser(accessToken);

                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user: user
                        }
                    });
                } else {
                    const user = await prepareGuestUser();
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: user
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };
        initialise();
    }, []);


    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                loginByOrganizationPatientId,
                iframeLogin,
                loginById,
                logout,
                register,
                registerSimpleById
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;