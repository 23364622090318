import { Box, Card, CardContent, Table, TableCell, TableRow, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Patient } from 'src/types/patient';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import ChangeAuthorizationsDialog from './ChangeAuthorizationsDialog';
import ChangeEmailDialog from './ChangeEmailDialog';
import ChangeMobileDialog from './ChangeMobileDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import DeleteAccountDialog from './DeleteAccountDialog';
import WearablesDialog from './Wearables';

interface ConfigurationDialogProps {
    open: boolean;
    onClose: () => void;
    directly_open_authorizations?: boolean;
    openDialogType?: string;
}

const ConfigurationDialog: FC<ConfigurationDialogProps> = ({ open, onClose, directly_open_authorizations, openDialogType }) => {
    const intl = useIntl();
    const [openChangeEmail, setOpenChangeEmail] = useState<boolean>(false);
    const [openChangeMobile, setOpenChangeMobile] = useState<boolean>(false);
    const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
    const [openChangeAuthorizations, setOpenChangeAuthorizations] = useState<boolean>(false);
    const [openDeleteAccount, setDeleteAccount] = useState<boolean>(false);
    const [openHealthStatistics, setOpenHealthStatistics] = useState<boolean>(false);

    const patient: Patient = JSON.parse(localStorage.getItem('user'));
    const [email, setEmail] = useState<string>(patient.email);
    const [phone, setPhone] = useState<string>(patient.mobile);

    const option_list = [
        { onClick: () => { setOpenChangeEmail(!openChangeEmail) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.change_email" }), extra: email },
        { onClick: () => { setOpenChangeMobile(!openChangeMobile) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.change_mobile" }), extra: `${phone.substring(0, 3)} ${phone.substring(3)}` },
        { onClick: () => { setOpenChangePassword(!openChangePassword) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.change_password" }) },
        { onClick: () => { setOpenChangeAuthorizations(!openChangeAuthorizations) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.authorizations" }) },
        { onClick: () => { setDeleteAccount(!openDeleteAccount) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.delete_account" }) },
        { onClick: () => { setOpenHealthStatistics(!openHealthStatistics) }, label: intl.formatMessage({ id: "accountManagment.Configuration.index.wearables" }) },
    ];

    useEffect(() => {
        if (directly_open_authorizations === true) {
            setOpenChangeAuthorizations(true);
        }
    }, [directly_open_authorizations]);

    useEffect(() => {
        if (openDialogType === 'authorizations') {
            setOpenChangeAuthorizations(true);
        } else if (openDialogType === 'change_email') {
            setOpenChangeEmail(true);
        } else if (openDialogType === 'change_mobile') {
            setOpenChangeMobile(true);
        } else if (openDialogType === 'change_password') {
            setOpenChangePassword(true);
        } else if (openDialogType === 'delete_account') {
            setDeleteAccount(true);
        } else if (openDialogType === 'wearables') {
            setOpenHealthStatistics(true);
        }
    }, [openDialogType]);

    return (
        <div>
            <CustomDialog open={open} onClose={onClose}>
                <Box>
                    <Typography variant='h4' gutterBottom>
                        <FormattedMessage id="accountManagment.Configuration.index.account_setup" />
                    </Typography>
                    <Box mt={2}> </Box>
                    <Card>
                        <CardContent style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '8px', paddingRight: '8px' }}>
                            <PerfectScrollbar>
                                <Box>
                                    <Table id="configurationTable">
                                        {option_list.map((option, index, array) => (
                                            <TableRow key={option.label} onClick={option.onClick}>
                                                <TableCell id="configurationTableRow" style={index === array.length - 1 ? { borderBottom: 'none' } : {}}>
                                                    <Box display="flex" justifyContent="space-between">
                                                        <div>
                                                            <Typography variant='h5'>
                                                                {option.label}
                                                            </Typography>
                                                            {option.extra && <Typography variant='body2' style={{ color: '#6B7280' }}>
                                                                {option.extra}
                                                            </Typography>}
                                                        </div>
                                                        <ChevronRight style={{ width: '24px', height: '24px' }} />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </CardContent>
                    </Card>
                </Box>
            </CustomDialog >
            <ChangeEmailDialog open={openChangeEmail} onClose={() => setOpenChangeEmail(false)} email={email} setEmail={setEmail} />
            <ChangeMobileDialog open={openChangeMobile} onClose={() => setOpenChangeMobile(false)} setPhone={setPhone} />
            <ChangePasswordDialog open={openChangePassword} onClose={() => setOpenChangePassword(false)} />
            <ChangeAuthorizationsDialog open={openChangeAuthorizations} onClose={() => setOpenChangeAuthorizations(false)} />
            <DeleteAccountDialog open={openDeleteAccount} onClose={() => setDeleteAccount(false)} />
            <WearablesDialog open={openHealthStatistics} onClose={() => setOpenHealthStatistics(false)} />
        </div >
    );
};

export default ConfigurationDialog;
