import { Box, Button, Card, CardActionArea, Divider, Popover, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import axios from 'axios';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PatientNotification } from 'src/types/patient_notification';
import { getWSConfig } from 'src/views/utils/Utils';
import NotificationsContent from './notificationsContent';

interface NotificationsPopperProps {
    open: boolean;
    onClose: () => void;
    notificationList: PatientNotification[];
    openDialog: () => void;
    anchorEl: null | HTMLElement;
    handleConfigurationOpen: () => void;
}

const NotificationsPopper: FC<NotificationsPopperProps> = ({ open, onClose, openDialog, notificationList, anchorEl, handleConfigurationOpen }) => {
    const config = getWSConfig();

    const getFirstThreeNotifications = () => {
        return notificationList.slice(0, 3);
    }
    const getNotAccessedNotificationsNumber = () => {
        if (!notificationList) return 0;
        return (notificationList.filter(notification => !notification.accessed)).length;
    }

    const notificationsAccessed = async () => {
        await axios.post(process.env.REACT_APP_SERVER_URL + '/patient_notification/1.0/set/all/accessed', config)
            .then(function (response) {
                let notifcationList = JSON.parse(localStorage.getItem('notifications'));
                notifcationList.forEach(notification => {
                    notification.accessed = true;
                });
                localStorage.setItem('notifications', JSON.stringify(notifcationList));
                window.dispatchEvent(new Event('notificationsUpdate'));
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    }

    useEffect(() => {
        if (open && getNotAccessedNotificationsNumber() > 0) {
            notificationsAccessed();
        }
    }, [open]);

    if (!notificationList || notificationList.length < 1) return null;
    else {
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ marginLeft: '1.5rem' }}
            >
                <Card>
                    <CardActionArea>
                        <Typography variant='h5' m={'1rem'}>
                            <FormattedMessage id="notifications.notifications" />
                        </Typography>
                        <NotificationsContent notificationList={getFirstThreeNotifications()} onClose={onClose} handleConfigurationOpen={handleConfigurationOpen} />
                        <Divider />
                        <Box display={'flex'} justifyContent={'center'}>
                            <Button onClick={() => { onClose(); openDialog() }}>
                                <FormattedMessage id="notifications.notificationsPoopover.see_all" />
                            </Button>
                        </Box>
                    </CardActionArea>
                </Card>
            </Popover>
        );
    }
}

export default NotificationsPopper;
