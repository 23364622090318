import { AppBar, Badge, Box, Button, SvgIcon, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Bell as BellIcon, Users as UsersIcon } from 'react-feather';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { PatientNotification } from 'src/types/patient_notification';
import ConfigurationDialog from 'src/views/accountManagment/ConfigurationDialog';
import ProfielDialog from 'src/views/accountManagment/ProfileDialog';
import { getWSConfig } from 'src/views/utils/Utils';
import useAuth from '../../../hooks/useAuth';
import DropDownMenu from './DropDownMenu';
import NotificationsDialog from './Notifications/notificationsDialog';
import NotificationsPopper from './Notifications/notificationsPopper';

interface TopBarProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'theme.shadows[16]',
        backgroundColor: '#ffffff',
    },
    toolbar: {
        minHeight: 64,
    },
    logInButton: {
        marginRight: '1rem'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: localStorage.getItem('showTopBar') === 'false' ? 0 : 64,
    },
}));

const TopBar: FC<TopBarProps> = ({
    className,
    ...rest
}) => {
    const classes = useStyles();
    const { isAuthenticated } = useAuth();
    const [openNotificationsPopper, setOpenNotificationsPopper] = useState<boolean>(false);
    const [openNotificationsDialog, setOpenNotificationsDialog] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<PatientNotification[]>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfigurationDialog, setOpenConfigurationDialog] = useState<boolean>(false);
    const [openProfileDialog, setOpenProfileDialog] = useState<boolean>(false);
    const [openConfigurationDialogDirectlyIntoAuthorizationScreen, setopenConfigurationDialogDirectlyIntoAuthorizationScreen] = useState<boolean>(false);
    const logo = window.localStorage.getItem('organization_logo')
    const intl = useIntl();
    const config = getWSConfig();

    useEffect(() => {
        window.addEventListener('notificationsUpdate', getNotificationList);
        getNotificationList();
        return () => {
            window.removeEventListener('notificationsUpdate', getNotificationList);
        };
    }, []); // Empty dependency array ensures this effect runs once

    const getNotificationList = async () => {
        let notificationList = window.localStorage.getItem('notifications');
        if (notificationList) {
            setNotifications(JSON.parse(notificationList));
        }
    }


    const handleNotificationsClick = (event) => {
        if (!notifications || notifications.length < 1) return;
        setOpenNotificationsPopper(true);
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationsClose = () => {
        setOpenNotificationsPopper(false);
    };

    const getNotAccessedNotificationsNumber = () => {
        if (!notifications) return 0;
        return (notifications.filter(notification => !notification.accessed)).length;
    }

    const handleConfigurationOpen = (directly_open_authorizations?: boolean) => {
        if (directly_open_authorizations) {
            setopenConfigurationDialogDirectlyIntoAuthorizationScreen(true)
        }
        setOpenConfigurationDialog(true);
    };

    const handleConfigurationClose = () => {
        setopenConfigurationDialogDirectlyIntoAuthorizationScreen(false)
        setOpenConfigurationDialog(false);
    };

    const handleProfileOpen = () => {
        setOpenProfileDialog(true);
    }

    const handleProfileClose = () => {
        setOpenProfileDialog(false);
    }

    return (
        <div style={{ paddingTop: localStorage.getItem('showTopBar') !== 'false' ? '4rem' : '0px' }}>
            <AppBar
                className={clsx(classes.root, className)}
                sx={{ display: 'block', displayPrint: 'none' }}
                {...rest}
            >
                <Toolbar className={classes.toolbar}>
                    <img
                        style={{ height: '1.65rem' }}
                        alt="Logo"
                        src={logo}
                    />
                    <Box ml={2} flexGrow={1} />
                    {isAuthenticated && (
                        <>
                            <Box ml={2} display="flex" alignContent='center' alignItems='center' >
                                <Badge badgeContent={getNotAccessedNotificationsNumber()} color="error" style={{ marginRight: '0.5rem', cursor: 'pointer' }} onClick={handleNotificationsClick}>
                                    <BellIcon color="gray" />
                                </Badge>
                                <DropDownMenu handleProfileOpen={handleProfileOpen} handleConfigurationOpen={handleConfigurationOpen} />
                            </Box>
                            <NotificationsPopper open={openNotificationsPopper} onClose={handleNotificationsClose} openDialog={() => setOpenNotificationsDialog(true)}
                                notificationList={notifications} anchorEl={anchorEl} handleConfigurationOpen={handleConfigurationOpen} />

                            <NotificationsDialog open={openNotificationsDialog} onClose={() => setOpenNotificationsDialog(false)}
                                notificationList={notifications} handleConfigurationOpen={handleConfigurationOpen} />

                            <ConfigurationDialog open={openConfigurationDialog} onClose={handleConfigurationClose} directly_open_authorizations={openConfigurationDialogDirectlyIntoAuthorizationScreen} />
                            <ProfielDialog open={openProfileDialog} onClose={handleProfileClose} />
                        </>
                    )}
                    {!isAuthenticated && (
                        <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                component={RouterLink}
                                to="/"
                                startIcon={
                                    <SvgIcon fontSize="small">
                                        <UsersIcon />
                                    </SvgIcon>
                                }
                                className={classes.logInButton}
                            >
                                {intl.formatMessage({ id: 'layouts.DashboardLayout.TopBar.index.enter' })}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                component={RouterLink}
                                to="/register"
                            >
                                {intl.formatMessage({ id: 'layouts.DashboardLayout.TopBar.index.register' })}
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );

};

export default TopBar;
