import React, { FC, useEffect, useRef, useState } from 'react';
import { Dialog, Container, Slide, Button, SvgIcon, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import useAuth from 'src/hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { ChevronLeft } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { addDialogToStack, removeDialogFromStack, getTopDialogId } from './DialogStackManager';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

interface CustomDialogProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fullScreen' | false;
    renderGoBackButton?: boolean;
    backgroundColor?: string;
    showTopBar?: boolean;
}

const CustomDialog: FC<CustomDialogProps> = ({ open, onClose, renderGoBackButton, maxWidth, backgroundColor, children, showTopBar }) => {
    const { isAuthenticated } = useAuth();
    const dialogId = useRef<string>(uuidv4());

    useEffect(() => {
        if (open === true) {
            window.addEventListener('popstate', handlePopState);
            window.history.pushState({ dialogId: dialogId.current }, '');
            addDialogToStack(dialogId.current);
        }
    }, [open]);

    const closeAndRemoveFromDialogStack = () => {
        onClose();
        window.removeEventListener('popstate', handlePopState);
        removeDialogFromStack(dialogId.current);
    }

    const handlePopState = (event: PopStateEvent) => {
        if (getTopDialogId() === dialogId.current) {
            closeAndRemoveFromDialogStack();
        }
    };

    const renderHeader = () => {
        return (
            <Button variant='text' color='tertiary' onClick={closeAndRemoveFromDialogStack} style={{ marginLeft: '-0.5rem', marginBottom: '1rem', marginTop: '1rem' }}>
                <SvgIcon fontSize="small" style={{ color: '#6B7280', }} >
                    <ChevronLeft />
                </SvgIcon>
                <Typography variant={'body2'} color={"#6B7280"}>
                    <FormattedMessage id="homepage.prescriptionCard.go_back" />
                </Typography>
            </Button>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={closeAndRemoveFromDialogStack}
            fullScreen
            TransitionComponent={Transition}
            PaperProps={{
                style: { backgroundColor: backgroundColor || '#F9FAFC', display: 'flex', flexDirection: 'column' }
            }}
        >
            {showTopBar !== false && isAuthenticated && (localStorage.getItem('showTopBar') !== "false") && (
                <TopBar />
            )}
            <div style={{ flex: 1, overflowY: 'auto' }}>
                {maxWidth === 'fullScreen' ? (
                    <div>
                        {(renderGoBackButton !== false && renderHeader())}
                        {children}
                    </div>
                ) : (
                    <Container maxWidth={maxWidth ?? 'md'}>
                        {(renderGoBackButton !== false && renderHeader())}
                        {React.isValidElement(children)
                            ? React.cloneElement(children, { closeAndRemoveFromDialogStack } as React.Attributes)
                            : children}
                    </Container>
                )}
            </div>
        </Dialog>
    );
};

export default CustomDialog;
