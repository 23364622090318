import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  X as XIcon,
} from 'react-feather';

interface ConfirmDialogProps {
  isOpen: boolean;
  setOpen: any;
  header: string | React.ReactNode;
  description: string | React.ReactNode;
  textButtonToRun: string | React.ReactNode;
  textButtonToDiscard: string | React.ReactNode;
  functionToRun: any;
  functionToClose?: any
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({ isOpen, setOpen, header, description, textButtonToRun, textButtonToDiscard,
  functionToRun, functionToClose }) => {
  const useStyles = makeStyles((theme) => ({

    root: {},
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cancelButton: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      "&:hover": {
        background: "#efefef"
      },
      marginLeft: '1rem',
    },
    xbutton: {
      marginRight: '-1rem',
    }
  }));

  const classes = useStyles();

  const handleClose = (): void => {
    if (functionToClose) functionToClose()
    else setOpen(false);
  };

  const handleAccept = (): void => {
    if (!functionToClose) functionToRun()
    else setOpen(false)
  }

  const handleOnBlur = (): void => {
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      className={classes.container}
      onClose={handleOnBlur}
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h4"
            color="textPrimary"
          >
            {header}
          </Typography>

          <IconButton onClick={handleClose} className={classes.xbutton} size="large">
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent={"flex-end"}
          gap={1}

        >
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            {textButtonToDiscard}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAccept}
          >
            {textButtonToRun}
          </Button>

        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
