import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AuthContext from 'src/contexts/JWTAuthContext';
import type { Medic } from 'src/types/medic';
import ConfirmDialog from 'src/views/utils/ConfirmDialog';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { getWSConfig } from 'src/views/utils/Utils';
import { localLog, localLogError } from '../../utils/logger';

interface ChangeAuthorizationsDialogProps {
  open: boolean;
  onClose: () => void;
}
const applyPagination = (medics: Medic[], page: number, limit: number): Medic[] => {
  return medics.slice(page * limit, page * limit + limit);
};

const useStyles = makeStyles((theme) => ({

  root: {},
  bold: {
    fontWeight: 'bold'
  },
  image: {
    height: '4rem',
    width: '7rem',
    marginRight: '1rem',
    cursor: 'pointer',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
}));

const ChangeAuthorizationsDialog: FC<ChangeAuthorizationsDialogProps> = ({
  open,
  onClose
}) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { enqueueSnackbar } = useSnackbar();
  const [medicToAuthorize, setMedicToAuthorize] = useState<Medic>();
  const [medicToUnauthorize, setMedicToUnauthorize] = useState<Medic>();
  const [isOpenAuthorizeMedicDialog, setOpenAuthorizeMedicDialog] = useState<boolean>(false);
  const [isOpenUnauthorizeMedicDialog, setOpenUnauthorizeMedicDialog] = useState<boolean>(false);
  const [medics, setMedics] = useState<Medic[]>([]);
  const selectedMedics = [];
  const config = getWSConfig();
  const { logout } = useContext(AuthContext);

  const getMyMedics = (): void => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/patient/1.0/my/medics`, config)
      .then(function (response) {
        if (response && response.data) {
          setMedics(response.data);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar(<FormattedMessage id="session_expired" />, { variant: 'error' });
          logout();
        } else {
          console.log("error loading professional medics from WS");
        }
      });
  }

  useEffect(() => {
    if (open && !medics.length) {
      getMyMedics();
    }
  }, [open]);

  const dialogAuthorizeMedic = (medic: Medic): void => {
    setMedicToAuthorize(medic);
    setOpenAuthorizeMedicDialog(true);
  }

  const authorizeMedic = (medic: Medic): void => {
    let url = process.env.REACT_APP_SERVER_URL + "/patient/1.0/accept/medic/authorization/" + medic.professional_medic_id;
    axios.post(url, config)
      .then(function (response) {
        enqueueSnackbar(<FormattedMessage id="accountManagment.Configuration.Authorizations.accept_request_accepted" />, { variant: "success" });
        if (response && response.data) {
          localLog("el WS de autorización ha devuelto", response.data);
        }
        medicToAuthorize.access = "Accepted";
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar(<FormattedMessage id="session_expired" />, { variant: 'error' });
          logout();
        }
        else {
          localLogError("error processing the authorization", error);
          enqueueSnackbar(<FormattedMessage id="accountManagment.Configuration.Authorizations.accept_request_error" />, { variant: 'error' });
        }
      })
      .finally(() => {
        setOpenAuthorizeMedicDialog(false);
      });
  }

  const dialogUnauthorizeMedic = (medic: Medic): void => {
    setMedicToUnauthorize(medic);
    setOpenUnauthorizeMedicDialog(true);
  }

  const unauthorizeMedic = (professionalMedicId: number): void => {
    let url = process.env.REACT_APP_SERVER_URL + '/patient/1.0/revoke/medic/authorization/' + professionalMedicId;
    axios.delete(url, config)
      .then(function (response) {
        enqueueSnackbar(<FormattedMessage id="accountManagment.Configuration.Authorizations.cancel_request_accepted" />, { variant: "success" });
        if (response && response.data) {
          localLog("el WS de autorización ha devuelto", response.data);
        }
        setMedics(medics.filter(medic => medic.professional_medic_id !== professionalMedicId));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar(<FormattedMessage id="session_expired" />, { variant: 'error' });
          logout();
        }
        else {
          enqueueSnackbar(<FormattedMessage id="accountManagment.Configuration.Authorizations.cancel_request_error" />, { variant: "error" });
        }
      })
      .finally(() => {
        setOpenUnauthorizeMedicDialog(false);
      });
  }

  const paginatedMedics = applyPagination(medics, page, limit);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Typography variant='h4' gutterBottom>
        <FormattedMessage id="accountManagment.Configuration.Authorizations.authorizations" />
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        <FormattedMessage id="accountManagment.Configuration.Authorizations.description" />
      </Typography>
      <Box mt={2}> </Box>
      <Card>
        <CardContent style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '8px', paddingRight: '8px' }}>
          {medicToAuthorize !== null && medicToAuthorize !== undefined &&
            <ConfirmDialog isOpen={isOpenAuthorizeMedicDialog} setOpen={setOpenAuthorizeMedicDialog}
              header={<FormattedMessage id="accountManagment.Configuration.Authorizations.description" />}
              description={
                <FormattedMessage id="accountManagment.Configuration.Authorizations.accept_authorization_description"
                  values={{
                    name: medicToAuthorize.name,
                    lastName: medicToAuthorize.last_name
                  }}
                />
              }
              textButtonToRun={<FormattedMessage id="accountManagment.Configuration.Authorizations.accept" />}
              textButtonToDiscard={<FormattedMessage id="accountManagment.Configuration.Authorizations.cancel" />}
              functionToRun={() => authorizeMedic(medicToAuthorize)} />
          }

          {medicToUnauthorize !== null && medicToUnauthorize !== undefined &&
            <ConfirmDialog isOpen={isOpenUnauthorizeMedicDialog} setOpen={setOpenUnauthorizeMedicDialog}
              header={<FormattedMessage id="accountManagment.Configuration.Authorizations.cancel_authorization" />}
              description={
                <FormattedMessage id="accountManagment.Configuration.Authorizations.cancel_authorization_description"
                  values={{
                    name: medicToUnauthorize.name,
                    lastName: medicToUnauthorize.last_name
                  }}
                />
              }
              textButtonToRun={<FormattedMessage id="accountManagment.Configuration.Authorizations.accept" />}
              textButtonToDiscard={<FormattedMessage id="accountManagment.Configuration.Authorizations.cancel" />}
              functionToRun={() => unauthorizeMedic(medicToUnauthorize.professional_medic_id)} />
          }

          <PerfectScrollbar>
            <Box minWidth={100}>
              <Table>
                {paginatedMedics.length < 1 &&
                  <Box p={2}>
                    <Typography
                      variant="h6"
                      color="inherit"
                    >
                      <FormattedMessage id="account.AccountView.Authorizations.no_medic_authorization" />
                    </Typography>
                  </Box>
                }
                {paginatedMedics.map((medic, index, array) => {
                  selectedMedics.includes(medic);
                  return (
                    <TableRow key={medic.professional_medic_id} >
                      <TableCell style={index === array.length - 1 ? { borderBottom: 'none' } : {}}>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Avatar
                            className={classes.avatar}
                            src={medic.avatar}
                          />
                          <div>
                            <Typography
                              variant="h6"
                              color="inherit"
                            >
                              {medic.name}  {medic.last_name}
                            </Typography>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={index === array.length - 1 ? { borderBottom: 'none' } : {}}
                      >
                        {medic.access === "Accepted" &&
                          <Button color="tertiary" variant="contained" onClick={() => dialogUnauthorizeMedic(medic)} >
                            <CheckIcon style={{ marginRight: '8px' }} /> <FormattedMessage id="accountManagment.Configuration.Authorizations.authorized" />
                          </Button>
                        }
                        {medic.access === "Pending" &&
                          <Button variant="contained" color="primary" onClick={() => dialogAuthorizeMedic(medic)} >
                            <AccessTimeIcon style={{ marginRight: '8px' }} /> <FormattedMessage id="accountManagment.Configuration.Authorizations.authorize" />
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Box>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </CustomDialog>
  );
};

export default ChangeAuthorizationsDialog;