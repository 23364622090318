import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Box,
    Button,
    LinearProgress,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getWSConfig } from 'src/views/utils/Utils';
import { useSnackbar } from 'notistack';
import { Patient } from 'src/types/patient';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { FormattedMessage, useIntl } from 'react-intl';

interface WearablesDialogProps {
    open: boolean;
    onClose: () => void;
}

interface WearableConnection {
    name: string;
    logo: string;
    provider: string;
    disabled: boolean;
    user_id: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    connectButton: {
        marginLeft: 'auto',
    },
    linearProgress: {
        margin: '4rem 1rem',
    },
}));

const WearablesDialog: FC<WearablesDialogProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const config = getWSConfig();
    const [loading, setLoading] = useState(true);
    const [connectingWearable, setConnectingWearable] = useState<string | null>(null);
    const [disconnectingWearable, setDisconnectingWearable] = useState<string | null>(null);
    const intl = useIntl();

    const initialWearableConnections = [
        { name: 'Fitbit', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Fitbit.png' },
        { name: 'Garmin', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Garmin.png' },
        { name: 'Google', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Google.png' },
        { name: 'Huawei', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Huawei.png' },
        { name: 'Peloton', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Peloton.png' },
        { name: 'Polar', logo: 'https://digimevo-miscellaneous-images.s3.eu-west-1.amazonaws.com/Polar.png' },
    ];

    const [wearableConnections, setWearableConnections] = useState<WearableConnection[]>(
        initialWearableConnections.map((connection) => ({
            ...connection,
            provider: connection.name.toUpperCase(),
            disabled: false,
            user_id: null,
        }))
    );

    const getPatientData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/auth/1.0/me', config);
            if (isMountedRef.current) {
                const patient: Patient = response.data;
                getTryTerraUserInfo(patient);
            }
        } catch (err) {
            enqueueSnackbar('Error loading patient data', { variant: 'error' });
        }
    }, [isMountedRef]);

    const connectWearable = (wearableName: string) => {
        setConnectingWearable(wearableName);
        const params = { resource: wearableName };
        axios.post(process.env.REACT_APP_SERVER_URL + '/patient/1.0/connect/tryterra/wearable', params, config)
            .then((response) => {
                window.location.href = response.data.auth_url;
            })
            .catch((error) => {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.Wearables.error_connecting" }) + wearableName, {
                    variant: 'error'
                });
            });
    };

    const disconnectWearable = (wearableName: string, user_id: string) => {
        setDisconnectingWearable(wearableName);
        const params = { resource: wearableName, user_id: user_id };
        axios.post(process.env.REACT_APP_SERVER_URL + '/patient/1.0/disconnect/tryterra/wearable', params, config)
            .then(() => {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.Wearables.disconnected_success" }) + wearableName, { variant: 'success' });
                window.location.reload();
            })
            .catch((error) => {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.Wearables.error_disconnecting" }) + wearableName, { variant: 'error' });
            });
    };

    const getTryTerraUserInfo = (patient: Patient) => {
        const params = { patient_id: patient.patient_id };
        axios.post(process.env.REACT_APP_SERVER_URL + '/patient/1.0/get/tryterra/user/info', params, config)
            .then((response) => {
                const users = response.data.users;
                const updatedConnections = wearableConnections.map((connection) => {
                    const matchingUser = users.find(
                        (user) => user.provider.toUpperCase() === connection.name.toUpperCase()
                    );
                    return {
                        ...connection,
                        disabled: matchingUser !== undefined,
                        user_id: matchingUser ? matchingUser.user_id : null,
                    };
                });
                setWearableConnections(updatedConnections);
                setLoading(false);
            })
            .catch((error) => {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.Wearables.error_data" }), { variant: 'error' });
            });
    };

    useEffect(() => {
        if (open) {
            getPatientData();
        }
    }, [open]);

    return (
        <CustomDialog open={open} onClose={onClose}>
            <Typography variant="h4" gutterBottom>
                <FormattedMessage id="accountManagment.Configuration.Wearables.wearables_title" />
            </Typography>
            {loading ?
                <LinearProgress className={classes.linearProgress} />
                :
                <Box mt={2}>
                    {wearableConnections.map((connection, index) => (
                        <Box key={index} display="flex" alignItems="center" m={2}>
                            <img
                                src={connection.logo}
                                alt={connection.name}
                                style={{ width: '50px', marginRight: '16px' }}
                            />
                            <Typography variant="h5">{connection.name}</Typography>
                            {connection.disabled ? (
                                <Button
                                    onClick={() => disconnectWearable(connection.name, connection.user_id!)}
                                    variant="contained"
                                    color="primary"
                                    className={classes.connectButton}
                                    disabled={disconnectingWearable === connection.name}
                                >
                                    {disconnectingWearable === connection.name ?
                                        intl.formatMessage({ id: "accountManagment.Configuration.Wearables.disconnecting" })
                                        :
                                        intl.formatMessage({ id: "accountManagment.Configuration.Wearables.connected" })
                                    }
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => connectWearable(connection.name)}
                                    variant="outlined"
                                    color="primary"
                                    className={classes.connectButton}
                                    disabled={connectingWearable === connection.name}
                                >
                                    {connectingWearable === connection.name ?
                                        intl.formatMessage({ id: "accountManagment.Configuration.Wearables.connecting" })
                                        :
                                        intl.formatMessage({ id: "accountManagment.Configuration.Wearables.connect" })
                                    }
                                </Button>
                            )}
                        </Box>
                    ))}
                </Box>
            }

        </CustomDialog>
    );
};

WearablesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default WearablesDialog;
