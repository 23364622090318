import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { RotateCcw as BackIcon } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { theme } from 'src/theme';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { localLogError } from 'src/views/utils/logger';
import { getWSConfig } from 'src/views/utils/Utils';

interface ChangeMobileDialogProps {
    open: boolean;
    onClose: () => void;
    setPhone: (phone: string) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    phoneTextfield: {
        "&.react-tel-input .special-label": {
            fontSize: '11px',
            left: '0.5rem',
            fontFamily: 'Roboto'
        },
        "&.react-tel-input .form-control:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        },
        "&.react-tel-input .invalid-number-message": {
            left: '0.5rem'
        }
    },
    successSnackbar: {
        backgroundColor: 'black',
        color: 'white'
    },
}));

const ChangeMobileDialog = ({ open, onClose, setPhone }: ChangeMobileDialogProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const config = getWSConfig();
    const { enqueueSnackbar } = useSnackbar();
    const patient = JSON.parse(localStorage.getItem('user'));
    const [mobile, setMobile] = useState(undefined);
    const [mobileSet, setMobileSet] = useState(false);
    const [code, setCode] = useState(Array(6).fill(''));
    const [isValid, setIsValid] = useState(true);
    const [codeError, setCodeError] = useState(false);
    const inputRefs = useRef([]);

    const handleMobileSubmit = (e) => {
        e.preventDefault();
        let formattedMobile = mobile.startsWith('+') ? mobile : `+${mobile}`;
        if (isValidPhoneNumber(formattedMobile) && mobile !== patient.mobile) {
            setMobile(formattedMobile);
            sendVerificationCode(formattedMobile);
            setMobileSet(true);
        } else {
            if (mobile === patient.mobile) {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeMobile.same_mobile" }), {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeMobile.valid_mobile" }), {
                    variant: 'error',
                });
            }
        }
    };

    const sendVerificationCode = (formattedMobile) => {
        let params = { mobile: formattedMobile.replace(/\s/g, '').replace(/[()]/g, '').replace(/-/g, '') };

        axios.post(process.env.REACT_APP_SERVER_URL + '/verification_code/1.0/send/sms', params, config)
            .catch((error) => {
                localLogError("Error sending verification code: ", error.response.data);
            });
    };

    const handleCodeSubmit = (enteredCode) => {
        const verificationCode = enteredCode || code.join('');

        if (verificationCode.length === 6) {
            axios.post(process.env.REACT_APP_SERVER_URL + '/verification_code/1.0/verify', { code: parseInt(verificationCode, 10) }, config)
                .then(response => {
                    if (response.data === true) {
                        updatePatientMobile();

                    } else {
                        enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeMobile.incorrect_code_try" }), {
                            variant: 'error',
                        });
                        setCodeError(true);
                    }
                })
                .catch(error => {
                    localLogError("Error verifying code: ", error.response.data);
                });
        } else {
            enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeMobile.incorrect_code_format" }), {
                variant: 'error',
            });
        }
    };

    const updatePatientMobile = () => {
        const params = { mobile: mobile.replace(/\s/g, '').replace(/[()]/g, '').replace(/-/g, '') };

        axios.patch(process.env.REACT_APP_SERVER_URL + '/auth/1.0/patient', params, config)
            .then(() => {
                enqueueSnackbar(<CustomSnackbarContent />, { persist: false });
                setPhone(mobile);
                onClose();
                setMobile(undefined);
                setMobileSet(false);
                setCode(Array(6).fill(''));
                patient.mobile = mobile;
                localStorage.setItem('user', JSON.stringify(patient));
            })
            .catch(error => {
                enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangeMobile.error" }), { variant: 'error' });
            });
    };

    const CustomSnackbarContent = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineOutlinedIcon style={{ marginRight: 8, color: 'white' }} />
            <FormattedMessage id="accountManagment.Configuration.ChangeMobile.mobile_successfully_changed" />
        </div>
    );

    const handlePhoneChange = (value) => {
        const formattedValue = value.startsWith('+') ? value : `+${value}`;
        setMobile(formattedValue);
        setIsValid(isValidPhoneNumber(formattedValue));
    };

    return (
        <CustomDialog open={open} onClose={() => { onClose(); setMobile(undefined); setMobileSet(false); setCode(Array(6).fill('')); }}>
            {!mobileSet ? (
                <form onSubmit={handleMobileSubmit}>
                    <Typography variant='h4' gutterBottom>
                        <FormattedMessage id="accountManagment.Configuration.ChangeMobile.mobile" />
                    </Typography>
                    <Card>
                        <CardContent>
                            <Typography variant='h5' gutterBottom>
                                <FormattedMessage id="accountManagment.Configuration.ChangeMobile.change_mobile" />
                            </Typography>
                            <Typography variant='subtitle1' gutterBottom>
                                <FormattedMessage id="accountManagment.Configuration.ChangeMobile.change_mobile_instructions" />
                            </Typography>
                            <PhoneInput
                                country={'es'}
                                value={mobile}
                                onChange={handlePhoneChange}
                                specialLabel={""}
                                inputStyle={{
                                    width: "100%",
                                    borderRadius: '8px',
                                    borderColor: isValid ? '#E6E8F0' : 'red',
                                    boxShadow: 'none',
                                    outline: 'none'
                                }}
                                containerClass={classes.phoneTextfield}
                            />
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{ marginTop: '16px' }}
                            >
                                <FormattedMessage id="accountManagment.Configuration.ChangeMobile.verification" />
                            </Button>
                        </CardContent>
                    </Card>
                </form>
            ) : (
                <form>
                    <Typography variant='h4' gutterBottom>
                        <FormattedMessage id="accountManagment.Configuration.ChangeMobile.mobile_verification" />
                    </Typography>
                    <Card>
                        <CardContent>
                            <Typography variant='h5' gutterBottom>
                                <FormattedMessage id="accountManagment.Configuration.ChangeMobile.mobile_verification_code" />
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                                {code.map((value, index) => (
                                    <TextField
                                        key={index}
                                        value={value}
                                        onChange={(e) => {
                                            const newCode = [...code];
                                            newCode[index] = e.target.value[0] || '';
                                            setCode(newCode);

                                            if (e.target.value.length === 1 && index < inputRefs.current.length - 1) {
                                                inputRefs.current[index + 1]?.focus();
                                            }

                                            if (newCode.every((digit) => digit.length === 1)) {
                                                handleCodeSubmit(newCode.join(''));
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Backspace' && !code[index] && index > 0) {
                                                inputRefs.current[index - 1]?.focus();
                                            }
                                        }}
                                        inputRef={(el) => inputRefs.current[index] = el}
                                        variant="outlined"
                                        margin="normal"
                                        sx={{ width: '50px', textAlign: 'center' }}
                                        inputProps={{
                                            style: {
                                                textAlign: 'center',
                                                fontSize: '20px',
                                                padding: '10px',
                                            },
                                            maxLength: 1,
                                        }}
                                        error={codeError}
                                    />
                                ))}
                            </Box>
                            <Button
                                onClick={sendVerificationCode}
                                style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}
                            >
                                <BackIcon style={{ marginRight: 8, color: theme.palette.neutral[500] }} />
                                <b><FormattedMessage id="accountManagment.Configuration.ChangeMobile.send_code_again" /></b>
                            </Button>
                        </CardContent>
                    </Card>
                </form>
            )}
        </CustomDialog>
    );
};

export default ChangeMobileDialog;
