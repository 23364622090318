import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import { localLogError } from 'src/views/utils/logger';
import { getWSConfig } from 'src/views/utils/Utils';
import * as Yup from 'yup';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const passwordRegExp = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  const intl = useIntl();
  const config = getWSConfig();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Formik
        initialValues={{
          password: '',
          passwordConfirm: '',
          oldpassword: '',
          success: "false",
          submit: null
        }}
        validationSchema={Yup.object().shape({
          oldpassword: Yup.string().min(8, intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.insert_current_password" }))
            .max(255).required(intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.insert_current_password" })),
          password: Yup.string().min(8,
            intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.new_password_requeriments" }))
            .max(255).required(intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.new_password_requeriments" })).matches(passwordRegExp,
              intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.new_password_requeriments" })),
          passwordConfirm: Yup.string().min(8, intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.insert_new_password_again" })).max(255)
            .required(intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.insert_new_password_again" })).matches(passwordRegExp,
              intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.insert_new_password_again" })),
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            if (values.password !== values.passwordConfirm) {
              setErrors({ submit: intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.error_new_password" }) });
            } else {
              let params = {
                old_password: values.oldpassword,
                new_password: values.password,
              };

              axios.patch(process.env.REACT_APP_SERVER_URL + '/auth/1.0/patient', params, config)
                .then(function (response) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar(intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.password_saved" }), { variant: 'success', persist: false, color: 'green' })
                })
                .catch(function (error) {
                  localLogError("update patient error: ", error.response.data);
                  if ((error.response.status === 401 && error.response.data.message === 'Invalid credentials') ||
                    (error.response.status === 400 && error.response.data.message[0].startsWith('old password needs at least one'))
                  ) {
                    setErrors({ submit: intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.error_incorrect_current_password" }) });
                  } else {
                    setErrors({ submit: intl.formatMessage({ id: "accountManagment.Configuration.ChangePassword.error_others" }) });
                  }
                })
            };
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>

            <Typography variant='h4' gutterBottom>
              <FormattedMessage id="accountManagment.Configuration.ChangePassword.change_password" />
            </Typography>
            <Box mt={2}> </Box>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.oldpassword && errors.oldpassword)}
                      fullWidth
                      helperText={touched.oldpassword && errors.oldpassword}
                      label={<FormattedMessage id="accountManagment.Configuration.ChangePassword.current_password" />}
                      name="oldpassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.oldpassword}
                      variant="outlined"
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={<FormattedMessage id="accountManagment.Configuration.ChangePassword.new_password" />}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                      fullWidth
                      helperText={touched.passwordConfirm && errors.passwordConfirm}
                      label={<FormattedMessage id="accountManagment.Configuration.ChangePassword.new_password_again" />}
                      name="passwordConfirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.passwordConfirm}
                      variant="outlined"
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <Box
                  alignItems="center"
                  display="flex"
                  mt={2}
                  ml={1}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="accountManagment.Configuration.ChangePassword.compulsory" />
                  </Typography>
                </Box>
                {errors.submit && (
                  <Box mt={3}>
                    <Alert severity="error">{errors.submit}</Alert>
                  </Box>
                )}
                {errors.success === "true" && (
                  <Box mt={3}>
                    <Alert severity="success">
                      <FormattedMessage id="accountManagment.Configuration.ChangePassword.password_modified" />
                    </Alert>
                  </Box>
                )}
              </CardContent>
              <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="accountManagment.Configuration.ChangePassword.change_password" />
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default ChangePasswordDialog;
