import { Box, Button, Card, CardActionArea, Dialog, Slide, SvgIcon, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import type { FC } from 'react';
import React from 'react';
import { ChevronLeft } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { PatientNotification } from 'src/types/patient_notification';
import NotificationsContent from './notificationsContent';

interface NotificationsDialogProps {
    open: boolean;
    onClose: () => void;
    notificationList: PatientNotification[];
    handleConfigurationOpen: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
})


const NotificationsDialog: FC<NotificationsDialogProps> = ({ open, onClose, notificationList, handleConfigurationOpen }) => {
    if (!notificationList) return null;
    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}
            PaperProps={{
                style: { backgroundColor: '#F9FAFC', padding: '1rem' }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Button variant='text' color='tertiary' onClick={onClose} >
                    <SvgIcon fontSize="small" style={{ marginRight: '0.5rem', color: '#6B7280' }} >
                        <ChevronLeft />
                    </SvgIcon>
                    <Typography variant={'body2'} color={"#6B7280"}>
                        <FormattedMessage id="notifications.notificationsDialog.go_back" />
                    </Typography>
                </Button>
            </Box>
            <Typography variant='h5' m={'1rem'}>
                <FormattedMessage id="notifications.notifications" />
            </Typography>
            <Card>
                <CardActionArea>
                    <NotificationsContent notificationList={notificationList} onClose={onClose} handleConfigurationOpen={handleConfigurationOpen} />
                </CardActionArea>
            </Card>
        </Dialog>
    );
}

export default NotificationsDialog;
