import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { FC } from 'react';
import {
  useEffect,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import type { Patient } from 'src/types/patient';
import CustomDialog from 'src/views/utils/CustomDialog/CustomDialog';
import PatientDataForm from './PatientDataForm';
import ProfileDetails from './ProfileDetails';

interface ProfileDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2rem',
  },
  backButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0'
  },
}));

const ProfielDialog: FC<ProfileDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const [patientdata, setPatientData] = useState<Patient>();

  useEffect(() => {
    let patient = localStorage.getItem('user')
    setPatientData(JSON.parse(patient));
  }, [open]);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Typography variant='h4' gutterBottom>
        <FormattedMessage id="accountManagment.Profile.index.your_profile" />
      </Typography>
      <Grid
        className={classes.root}
        container
        spacing={3}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          {patientdata != null && <ProfileDetails user={patientdata} />}
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          {patientdata != null && <PatientDataForm />}
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

export default ProfielDialog;
