const dialogStack: string[] = [];

export const addDialogToStack = (dialogId: string) => {
    dialogStack.push(dialogId);
};

export const removeDialogFromStack = (dialogId: string) => {
    const index = dialogStack.indexOf(dialogId);
    if (index !== -1) {
        dialogStack.splice(index, 1);
    }
};

export const getTopDialogId = () => {
    return dialogStack[dialogStack.length - 1];
};
