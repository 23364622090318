import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import routes, { renderRoutes } from 'src/routes';
import { theme } from './theme';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IntlProvider } from 'react-intl';
import { Patient } from './types/patient';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme { }
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
    const [loading, isLoading] = useState<boolean>(true);
    const [messages, setMessages] = useState<any>();
    var flatten = require('flat');
    var user = JSON.parse(localStorage.getItem('user'))
    var lang = localStorage.getItem('language')

    async function loadLocaleData(locale: string) {
        switch (locale) {
            case 'en':
                return import('./compiled-lang/en.json')
            case 'ca':
                return import('./compiled-lang/cat.json')
            case 'es':
                return import('./compiled-lang/es.json')
            default:
                return import('./compiled-lang/en.json')
        }
    }

    async function getMessages() {
        user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            const user: Patient = {
                patient_id: 0,
                avatar: "/static/logo_avatar.jpg",
                name: "Invitado",
                last_name: "",
                email: "",
                gender_id: null,
                birth_date: "",
                organization_id: 0,
                language: "es"
            }
            localStorage.setItem('user', JSON.stringify(user));
        }
        lang = localStorage.getItem('language');
        setMessages(await loadLocaleData((user && user.language && user.language.toLowerCase()) || lang || 'es'))
        isLoading(false);
    }


    useEffect(() => {
        window.addEventListener('userUpdated', getMessages);
        getMessages();
        return () => {
            window.removeEventListener('userUpdated', getMessages);
        };
    }, []);

    if (loading) return null;
    else {
        return (
            <StyledEngineProvider injectFirst>
                <IntlProvider
                    locale={(user && user.language) || lang || 'es'}
                    messages={flatten(messages)}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={theme}>
                            <StylesProvider jss={jss}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <SnackbarProvider
                                        dense
                                        maxSnack={3}
                                    >
                                        <Router history={history}>
                                            <AuthProvider>
                                                <GlobalStyles />
                                                <ScrollReset />
                                                <GoogleAnalytics />
                                                <CookiesNotification />
                                                {renderRoutes(routes)}
                                            </AuthProvider>
                                        </Router>
                                    </SnackbarProvider>
                                </MuiPickersUtilsProvider>
                            </StylesProvider>
                        </ThemeProvider>
                    </LocalizationProvider>
                </IntlProvider>
            </StyledEngineProvider >
        );
    }
};

export default App;
