import { getWSConfig } from 'src/views/utils/Utils';
import axios from 'axios';

/**
 * 
 * @param message the string we want to store
 * @description storage a message in a log file on aws to be allways accessible
 */
export function log(message: string) {
    const config = getWSConfig();
    let url = process.env.REACT_APP_SERVER_URL + '/logs/1.0/info';
    let params = { 'message': message };
    axios.post(url, params, config)
}

/**
 * 
 * @param message the string we want to store
 * @description storage an error message in a log file on aws to be allways accessible
 */
export function logError(error: string) {
    const config = getWSConfig();
    let url = process.env.REACT_APP_SERVER_URL + '/logs/1.0/error';
    let params = { 'message': error };
    axios.post(url, params, config)
}

/**
 * 
 * @param message the string we want to print
 * @description if we are in the local enviroment, logs the given messages
 */
export function localLog(custom_message: string, response) {
    if (process.env.REACT_APP_BUILD_ENV === 'local') {
        console.log(custom_message, response);
    }
}

/**
 * 
 * @param message the string we want to print
 * @description if we are in the local enviroment, logs the given messages
 */
export function localLogError(custom_message: string, error) {
    if (process.env.REACT_APP_BUILD_ENV === 'local') {
        if (error && error.response && error.response.data) {
            console.error(custom_message, error.response.data);
        }
    }
}